@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.report-section {
    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 100px;
        background-color: black;

        @media screen and (max-width: 1024px) {
            padding: 100px 40px;
        }

        @media screen and (max-width: 640px) {
            display: none;
        }

        @media screen and (min-width: 2560px) {
            padding: calc(100px * #{$zoom});
        }

        .explorationContainer .exploreCanvas .canvasFlexBox .displayAreaContainer .displayAreaViewport {
            background: black;
        }
    }

    &__content {
        width: 100%;
        max-width: 1240px;

        @media screen and (min-width: 2560px) {
            max-width: calc(1240px * #{$zoom});
        }
    }

    &__title {
        margin-bottom: 60px;
        color: $white;
        font-weight: 900;
        font-size: 40px;
        font-family: $secondary-font;
        text-transform: uppercase;

        @media screen and (min-width: 2560px) {
            margin-bottom: calc(60px * #{$zoom});
            font-size: calc(40px * #{$zoom});
        }
    }
}