.loading-circle {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}
.loading-circle div {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  margin: 0px;
  border: 4px solid #65656A;
  border-color: #65656A transparent transparent transparent;
  border-radius: 50%;
  animation: loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.loading-circle div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-circle div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-circle div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}