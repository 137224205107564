@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

.carousel-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 100px 140px;

  @media screen and (max-width: 1920px) and (min-height: 1079px) {
    min-height: 100vh;
  }

  @media screen and (max-width: 1024px) {
    padding: 60px 40px;
  }

  @media screen and (min-width: 2560px) {
    min-height: 100vh;
    padding: calc(100px * #{$zoom}) calc(100px * #{$zoom}) calc(140px * #{$zoom});
  }

  &__name {
    @include section-name(#65656a);
    @include line-above-text(238px, #65656a);
  }

  &__heading {
    @include section-heading();
    padding-top: 56px;
    font-family: $secondary-font--extrabold;

    @media screen and (max-width: 480px) {
      padding-top: 24px;
    }
  }

  &__content {
    @media screen and (max-width: 920px) {
      display: flex;
      flex-direction: column;
    }
  }

  .slide > div {
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    @media screen and (min-width: 2560px) {
      width: calc(100% - 5px);
    }
  }

  .carousel .slide {
    text-align: start;
  }

  &__carousel {
    width: 100%;
    max-width: 1240px;
    padding-top: 100px;

    @media screen and (max-width: 640px) {
      padding-top: 40px;
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(1240px * #{$zoom});
      padding-top: calc(100px * #{$zoom});
    }
  }
}

.carousel-with-controls {
  position: relative;

  @media screen and (max-width: 1640px) {
    max-width: calc(100vw - 200px);
  }

  @media screen and (max-width: 920px) {
    align-self: center;
    max-width: calc(100vw - 160px);
  }
}

.carousel-slider {
  &__image {
    width: 100%;
    max-width: 50%;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 50%;
    text-align: start !important;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }

    @media screen and (min-width: 2560px) {
      gap: calc(20px * #{$zoom});
    }
  }

  &__heading {
    font-weight: 800;
    font-size: 40px;
    font-family: $secondary-font;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 28px;
    }

    @media screen and (max-width: 380px) {
      font-size: 24px;
    }

    @media screen and (min-width: 2560px) {
      font-size: calc(40px * #{$zoom});
    }
  }

  &__description {
    font-size: 16px;
    font-family: $basic-font;
    text-align: left !important;

    @media screen and (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }
  }

  &__button {
    position: absolute;
    top: calc(50% + 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 640px) {
      width: 42px;
      height: 42px;
    }

    @media screen and (min-width: 2560px) {
        width: calc(64px * #{$zoom});
        height: calc(64px * #{$zoom});
    }

    img {
      @media screen and (max-width: 640px) {
        width: 42px;
        height: 42px;
      }
    }

    &:hover {
      background-color: #eeeef8;
    }
  }

  &__button-left {
    left: -85px;

    @media screen and (max-width: 1024px) {
      left: -75px;
    }

    @media screen and (max-width: 640px) {
      left: -65px;
    }

    @media screen and (min-width: 2560px) {
        left: calc(-85px * #{$zoom});
    }
  }

  &__button-right {
    right: -85px;

    @media screen and (max-width: 1024px) {
      right: -75px;
    }

    @media screen and (max-width: 640px) {
      right: -65px;
    }

    @media screen and (min-width: 2560px) {
        right: calc(-85px * #{$zoom});
    }
  }
}
