@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.form-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 132px 100px 100px;

  @media screen and (max-width: 1440px) {
    padding: 80px 100px 100px;
  }

  @media screen and (max-width: 1024px) {
    padding: 80px 40px 100px;
  }

  @media screen and (max-width: 480px) {
    padding: 60px 20px 40px;
  }

  @media screen and (min-width: 2560px) {
    padding: calc(132px * #{$zoom}) calc(100px * #{$zoom}) calc(100px * #{$zoom});
  }

  &__content {
    display: flex;
    gap: 70px;
    width: 100%;
    max-width: 1240px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(1240px * #{$zoom});
    }
  }

  &__title {
    @include section-heading(64px);
    max-width: 35%;
    color: #c1c1c5;
    font-family: $secondary-font--extrabold;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    span {
      color: $white;
    }

    @media screen and (max-width: 480px) {
      font-size: 48px;
      line-height: 54px;
    }
  }

  &__subtitle {
    color: $white;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    text-transform: uppercase;

    @media screen and (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
    }
  }

  &__description {
    padding-top: 16px;
    color: $white;
    font-size: 16px;
    font-family: $basic-font;

    @media screen and (min-width: 2560px) {
      padding-top: calc(16px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }
  }

  .form-box__row {
    display: flex;
    gap: 16px;

    .form-box__col {
      width: 50%;
    }
  }

  .tablet-hidden {
    display: none;
  }

  .form-input__textarea {
    min-height: 80px;

    @media screen and (min-width: 2560px) {
      min-height: calc(80px * #{$zoom});
    }
  }

  .main__form-wrapper {
    padding-top: 32px;

    @media screen and (min-width: 2560px) {
      padding-top: calc(32px * #{$zoom});
    }
  }

  .form-input__checkbox-label,
  .form-input__radio-label {
    color: $white;
  }

  .form-input__checkbox-label:before {
    background: white;
    border: 2px solid $white;
  }

  .form-input__checkbox-label {
    margin-top: 17px;
    font-weight: 400;
    font-size: 16px;

    @media screen and (min-width: 2560px) {
      margin-top: calc(17px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }
  }

  input:checked + .form-input__checkbox-label:before {
    background-color: #f9225a;
    border-color: #f9225a;
  }

  input,
  textarea {
    color: white;
    border: 2px solid $white;
  }

  input[name='firstname'],
  input[name='lastname'] {
    width: 100%;
    max-width: 100%;
  }

  input::placeholder,
  textarea::placeholder {
    color: $white;
    text-transform: none;
  }

  .form-box__description {
    margin-top: 32px;
    color: $white;

    @media screen and (min-width: 2560px) {
      margin-top: calc(32px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }

    a {
      color: $white;
    }
  }

  .main__form-button {
    width: 100%;
    max-width: 376px;
    margin-top: 32px;
    padding: 14px 16px;
    color: $black;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    line-height: 20px;
    text-transform: uppercase;
    background-color: $white;
    border: none;
    outline: none;
    cursor: pointer;

    @media screen and (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      margin-top: calc(32px * #{$zoom});
      padding: calc(14px * #{$zoom}) calc(16px * #{$zoom});
      font-size: calc(16px * #{$zoom});
      line-height: calc(20px * #{$zoom});
    }

    &:hover {
      color: white;
      background-color: #f9225a;
    }
  }

  .main__success .main__form-title {
    color: #fff;
    font-size: 32px;
    font-family: $secondary-font;
  }

  .main__success .main__form-description {
    margin-top: 24px;
    color: #fff;
  }
}
