@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.upload-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 100px 200px;
  background-color: $white;

  @media screen and (max-width: 1024px) {
    padding: 60px 40px 100px;
  }

  @media screen and (max-width: 480px) {
    padding: 60px 20px 100px;
  }

  @media screen and (min-width: 2560px) {
    padding: calc(100px * #{$zoom}) calc(100px * #{$zoom}) calc(200px * #{$zoom});
  }

  &__content {
    width: 100%;
    max-width: 1240px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1240px * #{$zoom});
    }
  }

  &__name {
    @include section-name(#65656a);
    @include line-above-text(238px, #65656a);
  }

  &__sneak {
    padding-top: 22px;
    font-weight: 800;
    font-size: 20px;
    font-family: $secondary-font--extrabold;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (min-width: 2560px) {
      padding-top: calc(48px * #{$zoom});
      font-size: calc(20px * #{$zoom});
    }
  }

  &__examples {
    padding-top: 100px;

    @media screen and (max-width: 640px) {
      padding-top: 40px;
    }

    @media screen and (min-width: 2560px) {
      padding-top: calc(120px * #{$zoom});
    }
  }

  &__examples-title {
    color: $black;
    font-weight: 800;
    font-size: 40px;
    font-family: $secondary-font;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 32px;
    }

    @media screen and (min-width: 2560px) {
      font-size: calc(40px * #{$zoom});
    }
  }

  &__examples-items {
    display: flex;
    justify-content: space-between;
    padding-top: 45px;

    @media screen and (max-width: 1440px) {
      gap: 40px;
    }

    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    @media screen and (min-width: 2560px) {
      padding-top: calc(64px * #{$zoom});
    }
  }

  &__examples-items_mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;

    .carousel-slider__slide {
      height: 100%;
    }

     .carousel-slider__image {
      max-height: 400px;
      filter: none;
     }

    &__button {
      width: 100%;
      height: 48px;
      // margin-top: 36px;
      color: $white;
      font-weight: 800;
      font-size: 16px;
      font-family: $secondary-font;
      text-transform: uppercase;
      background-color: $black;
      border: 2px solid $black;
      outline: none;

      &:disabled,
      &:focus:disabled,
      &:hover:disabled {
        color: #f1f1f4;
        background-color: #65656a;
        border: 2px solid #65656a;
      }

      &:hover {
        color: $black;
        background-color: $white;
      }
    }
  }

  &__example-item {
    position: relative;
    max-width: 348px;
    max-height: 300px;
    overflow: hidden;
    
    @media screen and (max-width: 1440px) {
      max-width: calc(33% - 28px);
    }

    @media screen and (max-width: 640px) {
      max-width: calc(50% - 20px);
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(348px * #{$zoom});
      max-height: calc(300px * #{$zoom});
    }

    &:nth-of-type(2) {
      .upload-section__example-image{
        object-position: bottom;
      } 
    }
  }

  &__example-image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    &:nth-of-type(3) {
      min-height: 100%;
    }

    filter: grayscale(1);

    &:hover {
      filter: none;
    }
  }

  &__example-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 4px 10px;
    font-weight: 900;
    font-size: 14px;
    font-family: $secondary-font;
    text-transform: uppercase;
    background: $white;
    border: 1px solid $black;
    cursor: pointer;
    transition: all 0.2s linear;

    @media screen and (min-width: 2560px) {
      font-size: calc(14px * #{$zoom});
    }

    &:disabled {
      cursor: not-allowed;

      &:hover {
        color: #65656a;
        background: $white;
        border: 1px solid $black;
        transform: scale(1);
      }
    }

    &:hover {
      color: $white;
      background: $black;
      border: 1px solid $white;
      transform: scale(1.1);
      transition: all 0.2s linear;
    }
  }

  &__report-wrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: 140px;
  }
}

.user-upload-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 130px;

  @media screen and (max-width: 1024px) {
    gap: 35px;
    padding-top: 60px;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }

  @media screen and (min-width: 2560px) {
    gap: calc(40px * #{$zoom});
    padding-top: calc(130px * #{$zoom});
  }

  &__area {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    min-height: 380px;
    background-color: $white;
    box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    @media screen and (min-width: 1440px) {
      min-height: 380px;
    }

    @media screen and (min-width: 2560px) {
      min-height: calc(380px * #{$zoom});
    }

    &:hover {
      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='0' ry='0' stroke='%23D8D9DA' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      max-width: 100%;
      aspect-ratio: unset;
    }

    @media screen and (max-width: 480px) {
      background-image: none;
      border: 1px solid $black;
      border-radius: 0;
    }

    &_has-result {
      margin-top: 60px;
    }
  }

  &__area-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    color: #65656a;

    p {
      margin-top: 28px;
      font-weight: 900;
      font-size: 14px;
      font-family: $secondary-font;
      text-align: center;
      text-transform: uppercase;

      @media screen and (min-width: 2560px) {
        font-size: calc(14px * #{$zoom});
      }
    }

    span {
      display: block;
      margin-top: 20px;
      font-weight: 400;
      font-family: $basic-font;
      text-transform: none;

      @media screen and (min-width: 2560px) {
        margin-top: calc(20px * #{$zoom});
      }
    }
  }

  &__area-image {
    max-width: 64px;
    margin-bottom: 25px;
  }

  &__with-response {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: flex-start;

    img {
      width: 100%;
      max-width: 50%;
      height: 100%;
      object-fit: contain;
    }

    .desktop-view {
      @media screen and (max-width: 840px) {
        display: none;
      }
    }
    .tablet-view {
      @media screen and (min-width: 841px) {
        display: none;
      }
    }

    &__right-col {
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: 100%;
    }

    &__response {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      margin-top: 20px;

      @media screen and (max-width: 840px) {
        flex-direction: row;
      }

      @media screen and (min-width: 2560px) {
        gap: calc(12px * #{$zoom});
        margin-top: calc(20px * #{$zoom});
      }
    }

    &__details {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px 28px;
      border: 1px solid #000;

      &_loading {
        border: 1px solid #65656A;
      }

      @media screen and (max-width: 1280px) {
        padding: 28px 12px;
      }

      @media screen and (max-width: 920px) {
        flex-direction: column;
      }

      .desktop-view {
        display: none;
      }

      .mobile-view {
        @media screen and (max-width: 840px) {
          display: none;
        }
      }

      p {
        width: 100%;
        max-width: fit-content;
        color: $black;
        font-weight: 900;
        font-size: 44px;
        font-family: $secondary-font;
        text-transform: uppercase;

        &.numbers-plate_loading {
          opacity: 0.5;
        }

        @media screen and (max-width: 1280px) {
          font-size: 48px;
        }

        @media screen and (min-width: 2560px) {
          font-size: calc(40px * #{$zoom});
        }
      }
    }
  }

  &__with-response-title {
    color: $black;
    font-weight: 900;
    font-size: 24px;
    font-family: $secondary-font;
    text-transform: uppercase;

    @media screen and (min-width: 2560px) {
      font-size: calc(24px * #{$zoom});
    }
  }

  &__legal-info {
    margin-top: 40px;

    /*&_has-result {
      @media screen and (max-width: 640px) {
        display: none;
      }
    }
    */

    @media screen and (min-width: 2560px) {
      margin-top: calc(40px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      gap: 24px;
    }

    &_loading {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_has-result {
      @media screen and (max-width: 640px) {
        display: none;
      }
    }
  }

  &__title {
    color: $black;
    font-weight: 900;
    font-size: 40px;
    font-family: $secondary-font;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 32px;
      line-height: 1;
    }

    @media screen and (min-width: 2560px) {
      font-size: calc(40px * #{$zoom});
    }
  }

  &__result-title {
    @media screen and (min-width: 641px) {
      display: none;
    }
  }

  &__description {
    color: $black;
    font-weight: 400;
    font-size: 16px;
    font-family: $basic-font;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }

    @media screen and (min-width: 2560px) {
      font-size: calc(24px * #{$zoom});
    }
  }

  &__loading-title {
    color: #65656a;
    font-weight: 900;
    font-size: 40px;
    font-family: $secondary-font;
    text-transform: uppercase;

    @media screen and (min-width: 2560px) {
      font-size: calc(40px * #{$zoom});
    }
  }

  &__image-preview {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    opacity: 0.4;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 480px) {
      border-radius: 0;
    }
  }

  &__btn {
    position: relative;
    z-index: 12;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 298px;
    min-height: 48px;
    padding: 6px 16px;
    background-color: $black;
    border: 2px solid $black;
    outline: none;
    cursor: pointer;
    transition: all 0.2s linear;

    @media screen and (min-width: 2560px) {
      max-width: calc(298px * #{$zoom});
      min-height: calc(48px * #{$zoom});
      padding: calc(6px * #{$zoom}) calc(16px * #{$zoom});
    }

    &:hover {
      background-color: $white;

      span {
        color: $black;
      }

      img {
        filter: invert(1);
      }
    }

    span {
      margin-top: 0;
      color: $white;
      font-weight: 800;
      font-size: 16px;
      font-family: $secondary-font--extrabold;
      line-height: 20px;
      text-transform: uppercase;

      @media screen and (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
        line-height: calc(20px * #{$zoom});
      }
    }

    img {
      width: 32px;
      aspect-ratio: 1;

      @media screen and (min-width: 2560px) {
        width: calc(32px * #{$zoom});
      }
    }

    &_loading {
      background: #f1f1f4;
      border: none;
      pointer-events: none;

      &:hover {
        background-color: #f1f1f4;
      }

      span {
        color: #65656a;
      }
    }
  }

  &__loading-text {
    font-size: 16px;
  }

  &__error {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    color: #f9225a;
    font-weight: 800;
    font-family: $secondary-font;
    text-transform: uppercase;

    span {
      max-width: 50%;
      color: $black;
      font-weight: 400;
      font-family: $basic-font;
      text-transform: none;
    }
  }
}

.mobile-upload {
  display: none;

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
  }

  .user-upload-section__btn {
    align-self: center;
    margin-top: 30px;

    @media screen and (max-width: 540px) {
      max-width: 100%;
    }
  }

  .user-upload-section__error {
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    border: 1px solid #f9225a;

    span {
      max-width: 100%;
    }
  }

  .user-upload-section__result-title {
    margin-bottom: 32px;
    color: #65656a;
    font-weight: 900;
    font-size: 32px;
    font-family: $secondary-font;
  }

  &__result-title {
    color: #65656a;
    font-weight: 900;
    font-size: 32px;
    font-family: $secondary-font;
  }

  &__result-details {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 20px;

    img,
    p {
      width: 100%;
      max-width: 50%;
    }

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 100%;
      padding: 14px 20px;
      font-weight: 900;
      font-size: 24px;
      font-family: $secondary-font;
      border: 1px solid #000;
    }
  }

  &__result-image {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  }
}
