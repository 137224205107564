@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.hero-section {
  min-height: 100vh;
  margin-top: 90px;
  overflow: hidden;
  background-image: url('../../../asset/resource/hero-background.png');
  background-repeat: no-repeat;
  background-position: 20% 50%;
  background-size: cover;

  @media screen and (max-width: 1240px) {
    background-position: 80% 50%;
  }

  @media screen and (max-width: 1024px) {
    min-height: unset;
    background-image: url('../../../asset/resource/hero-background-mobile.png');
    background-position: 80% 50%;
  }

  @media screen and (max-width: 640px) {
    margin-top: 65px;
    background-color: $black;
    background-image: none;
  }

  @media screen and (min-width: 2600px) {
    background-image: url('../../../asset/resource/hero-background-mobile.png');
  }

  &__content {
    position: relative;
    padding: 100px 100px 0;

    @media screen and (max-width: 1640px) {
      padding: 50px 100px 0;
    }

    @media screen and (max-width: 1024px) {
      padding: 50px 40px 0;
    }

    @media screen and (max-width: 480px) {
      padding: 50px 20px 0;
    }

    @media screen and (min-width: 2560px) {
        padding: calc(100px * #{$zoom}) calc(100px * #{$zoom}) 0;
      }
  }

  &__heading {
    color: $white;
    font-size: 96px;
    font-family: $secondary-font;
    line-height: 104px;
    text-transform: uppercase;

    @media screen and (max-width: 1240px) {
      font-size: 80px;
      line-height: 84px;
    }

    @media screen and (max-width: 640px) {
      font-size: 60px;
      line-height: 66px;
    }

    @media screen and (max-width: 480px) {
      font-size: 44px;
      line-height: 48px;
    }

    @media screen and (min-width: 2560px) {
      font-size: calc(96px * #{$zoom});
      line-height: calc(104px * #{$zoom});
    }
  }

  &__sub-heading {
    margin-top: 20px;
    color: $white;
    font-weight: 900;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (max-width: 540px) {
      font-size: 24px;
    }

    @media screen and (min-width: 2560px) {
      margin-top: calc(20px * #{$zoom});
      font-size: calc(32px * #{$zoom});
    }
  }

  &__description {
    position: relative;
    z-index: 44;
    max-width: 572px;
    margin-top: 54px;
    padding-top: 20px;
    color: $white;
    font-size: 20px;
    font-family: $basic-font;
    @include line-above-text(125px, #f9225a);

    @media screen and (max-width: 1640px) {
      margin-top: 24px;
    }

    @media screen and (max-width: 1024px) {
      z-index: 1;
    }

    @media screen and (max-width: 820px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    @media screen and (max-width: 640px) {
      margin-top: 24px;
      font-size: 18px;
      line-height: 27px;
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(572px * #{$zoom});
      margin-top: calc(54px * #{$zoom});
      padding-top: calc(20px * #{$zoom});
      font-size: calc(20px * #{$zoom});
    }
  }

  &__additional-description-wrapper {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 100px 100px;

    @media screen and (max-width: 1024px) {
      padding: 0 40px 60px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 480px) {
      padding: 0 20px 60px;
    }

    @media screen and (min-width: 2560px) {
      padding: 0 calc(100px * #{$zoom}) calc(100px * #{$zoom});
    }
  }

  &__additional-description {
    position: relative;
    @include line-above-text(125px, #f9225a);
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 50%;
    margin-top: 36px;
    padding-top: 20px;
    color: $white;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    @media screen and (min-width: 2560px) {
      margin-top: calc(36px * #{$zoom});
      padding-top: calc(20px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }

    p {
      position: relative;
      max-width: 572px;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }

      @media screen and (min-width: 2560px) {
        max-width: calc(572px * #{$zoom});
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    min-height: 64px;
    max-height: 64px;
    margin: 0 auto;
    color: $white;
    font-weight: 900;
    font-size: 18px;
    font-family: $secondary-font;
    text-transform: uppercase;
    background-color: $black;
    border: 2px solid $white;
    cursor: pointer;

    @media screen and (max-width: 640px) {
      display: none;
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(280px * #{$zoom});
      min-height: calc(64px * #{$zoom});
      margin-top: calc(64px * #{$zoom});
      font-size: calc(18px * #{$zoom});
    }

    &:hover {
      color: $black;
      background-color: $white;
    }

    &_mobile {
      display: none;

      @media screen and (max-width: 640px) {
        display: flex;
        margin-top: 41px;
      }
    }
  }

  &__hero-car {
    position: absolute;
    right: 15%;
    bottom: -15%;
    max-width: 35vw;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    @media screen and (min-width: 2560px) {
      max-width: 45vw;
    }

    @media screen and (min-width: 2600px) {
      display: none;
    }

    img {
      position: relative;
      width: 100%;

    }
  }

  &__animation-anchor {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 15.5vw;
      left: 12.5vw;
      z-index: 3;
      width: 9.5vw;
      height: 2.5vw;
      border: 4px solid #17C583;

      @media screen and (min-width: 2320px) {
        top: 358px;
        left: 285px;
      }

      @media screen and (min-width: 2560px) {
        top: 13.8vw;
        left: 10.8vw;
      }
    }
  }

  .animated-numbers {
    position: absolute;
    top: 9.3vw;
    left: 25.8vw;
    z-index: 5;
    width: 220px;
    color: #17C583;
    font-weight: 900;
    font-size: 34px;
    font-family: $secondary-font;
    text-transform: uppercase;
    border-bottom: 4px solid #17C583;

    @media screen and (max-width: 1560px) {
      top: 8.8vw;
      left: 25.5vw;
    }

    @media screen and (max-width: 1520px) {
      top: 8.7vw;
      left: 25.5vw;
    }

    @media screen and (max-width: 1440px) {
      top: 8.3vw;
      left: 25.5vw;
    }

    @media screen and (min-width: 2320px) {
      top: 221px;
      right: -28px;
      left: unset;
    }

    @media screen and (min-width: 2560px) {
      top: 8.51vw;
      left: 24.1vw;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -30px;
      bottom: -2.24vw;
      left: -63px;
      left: -4.85vw;
      width: 75px;
      width: 5.8vw;
      height: 4px;
      background: #17C583;
      transform: rotate(-45deg);

      @media screen and (max-width: 1440px) {
        bottom: -2.4vw;
        left: -4.7vw;
        width: 5.72vw;
        transform: rotate(-49deg);
      }

      @media screen and (min-width: 2220px) {
        transform: rotate(-47deg);
      }

      @media screen and (min-width: 2320px) {
        bottom: -50px;
        left: -120px;
        width: 142px;
        transform: rotate(-42deg);
      }

      @media screen and (min-width: 2560px) {
        transform: rotate(-41.5deg);
      }
    }
  }

  &__hero-car-mobile {
    display: none;

    @media screen and (max-width: 640px) {
      display: block;
      width: 100%;
      margin-top: 50px;
    }
  }
}
