@import 'styles/variables';
@import 'styles/extensions';

.not-found-page {
  @extend %wrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

}