@import 'styles/variables';
@import 'styles/extensions';

.header {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 90px;
  color: $white;
  background: $black;

  @media screen and (max-width: 480px) {
    min-height: 65px;
  }

  &__wrapper {
    @extend %header-wrapper;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__event-logo-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: flex;
    align-items: flex-start;
    padding-right: 40px;

    @media screen and (max-width: 480px) {
      gap: 0;
      padding-right: 0px;
    }
    
    img {
      height: 20px;

      @media screen and (min-width: 2560px) {
        height: calc(20px * #{$zoom});
      }
    }
  }

  &__company-logo {
    padding-right: 20px;
  }

  &__title {
    color: #CFCFCF;
    font-weight: 800;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    letter-spacing: 0.2px;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }

    @media screen and (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }
  }

  &__event-logo {
    padding-left: 20px;
    font-weight: 900;
    font-size: 24px;
    font-family: $secondary-font;
    line-height: 24px;
    text-transform: uppercase;
    border-left: 1px solid $white;
    cursor: pointer;
    @media (min-width: 2560px) {
      font-size: calc(24px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

  }

  nav {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__navigation-link {
    position: relative;
    display: flex;
    align-items: center;
    height: fit-content;
    padding: 4px 12px;
    color: #909095;
    font-weight: 900;
    font-size: 16px;
    font-family: $secondary-font--extrabold;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.5s ease;

    &:hover {
      border-bottom: 2px solid #F9225A;
      cursor: pointer;
      transition: all 0.3s ease;

    }

    @media screen and (max-width: 1440px) {
      padding: 6px 6px;
      font-size: 14px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

    &.active {
      color: $black;
      background-color: #D7D7DC;

      &:hover {
        border: 2px solid transparent;
      }

        &::after {
          left: 0;
          width: 100%;
        }
      
    }
  }

  &__button {
    position: relative;
    display: flex;
    width: 52px;
    height: 52px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: transparent;
    border-left: 1px solid transparent;
    outline: none;
    cursor: pointer;
    transition: border 0.5s;

    @media (min-width: 2560px) {
      width: calc(50px * #{$zoom});
      height: calc(50px * #{$zoom});
      margin: calc(5px * #{$zoom}) 0;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 16px;
      height: 2px;
      margin: 0 auto;
      background-color: $white;
      box-shadow: 0 -6px 0 0 $white;
      transform: translateY(-50%);
      content: '';

      @media (min-width: 2560px) {
        width: calc(16px * #{$zoom});
        height: calc(2px * #{$zoom});
      }
    }

    &::after {
      right: -8px;
      left: -4px;
      width: 12px;
      box-shadow: 0 6px 0 0 $white;

      @media (min-width: 2560px) {
        right: calc(-8px * #{$zoom});
        left: calc(-4px * #{$zoom});
        width: calc(12px * #{$zoom});
      }
    }

    &_cross {
      &::before,
      &::after {
        width: 16px;
        box-shadow: 0 0 0 0 $white;
        transition: transform 0.2s 0.1s, box-shadow 0.1s, width 0.1s, left 0s 0.1s;

        @media (min-width: 2560px) {
          width: calc(16px * #{$zoom});
        }
      }

      &::before {
        background-color: $white;
        transform: translateY(-50%) rotate(45deg);
      }

      &::after {
        left: -8px;
        background-color: $white;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  &__menu-navigation {
    position: fixed;
    top: 95px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(7px);

    @media screen and (max-width: 475px) {
      top: 65px;
    }
  }

  &__menu-navigation-links {
    position: absolute;
    z-index: 111;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 65vh;
    margin: 0 auto 0;
    padding: 0 40px;
    background: $black;

    &_close {
      position: absolute;
      z-index: 111;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 65vh;
      margin: 0 auto 0;
      padding: 0 40px;
      background: $black;
      transform: translateY(-100%);
      transition: 0.3s all ease-in-out;
    }

    &_open {
      position: absolute;
      top: -10px;
      z-index: 111;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 50vh;
      margin: 0 auto 0;
      padding: 0 40px;
      color: $white;
      background: $black;
      transform: translateY(0);
      transition: 0.3s all ease-in-out;

      @media screen and (max-width: 480px) {
        top: -3px;
      }
    }
  }

  &__top-navigation-link {
    position: relative;
    width: fit-content;
    font-weight: bold;

    &:hover {
      color: $white;
      cursor: pointer;
    }

    @include customLinkAnimation($white, 2px);
  }

  &__force-refresh {
    display: none;
    width: 80px;
    height: 80px;
    margin-right: 15px;
    margin-left: auto;
    background: transparent;
    border: none;
  }
}

.header__top-navigation-link-appear {
  transform: translatex(800px);
}

.header__top-navigation-link-appear-done {
  transform: translatex(0);
  transition: transform 1s ease, box-shadow 0.2s ease;
}