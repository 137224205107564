@mixin section-heading($line-height: 64px) {
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: $line-height;

    @media screen and (max-width: 1024px) {
        font-size: 42px;
        line-height: 46px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
        line-height: 36px;
    }

    @media screen and (min-width: 2560px) {
        font-size: calc(60px * #{$zoom});
        line-height: calc(64px * #{$zoom});
    }
  }

  @mixin section-name($color) {
    position: relative;
    padding-top: 10px;
    color: $color;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    line-height: 36px;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
    }

    @media screen and (min-width: 2560px) {
        padding-top: calc(10px * #{$zoom});
        font-size: calc(32px * #{$zoom});
        line-height: calc(28px * #{$zoom});
    }
  }

@mixin line-above-text($width, $color) {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        width: $width;
        height: 2px;
        background: $color;

        @media screen and (min-width: 2560px) {
            width: calc(#{$width} * #{$zoom});
        }
    }
}

@mixin line-to-circle($property) {
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        #{$property}: -125px;
        // width: 125px;
        height: 2px;
        background: #F9225A;
        // animation: line-appearing 1s ease 3s forwards;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

@keyframes line-appearing {
    0% {
        width: 0;
    }

    100% {
        width: 125px;
    }
}