@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.benefits-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  color: $white;
  background-color: $black;

  @media screen and (max-width: 1240px) {
    padding: 100px 40px 100px;
  }

  @media screen and (max-width: 480px) {
    padding: 60px 20px 60px;
  }

  @media screen and (min-width: 2560px) {
    padding: calc(100px * #{$zoom});
  }

  &__content {
    max-width: 1240px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1240px * #{$zoom});
    }
  }

  &__section-type {
    @include section-name(#f1f1f4);
    @include line-above-text(238px, white);
  }

  &__section-heading {
    @include section-heading();
    padding-top: 56px;
    font-family: $secondary-font--extrabold;

    @media screen and (max-width: 480px) {
      padding-top: 24px;
    }
  }

  &__item {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding-top: 150px;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }

    @media screen and (max-width: 640px) {
      flex-direction: column;
      padding-top: 60px;
    }

    &:nth-of-type(1) {
      .benefits-section__image {
        object-position: left bottom !important;
        max-width: 450px;
        max-height: 161px;

        @media screen and (max-width: 1024px) {
          max-width: 50%;
          max-height: unset;
        }

        @media screen and (max-width: 640px) {
          max-width: 100%;
        }

        @media screen and (min-width: 2560px) {
          max-width: calc(450px * #{$zoom});
          max-height: calc(161px * #{$zoom});
        }
      }

      .benefits-section__image-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        min-width: 450px;
        height: 100%;
        min-height: 161px;

        @media screen and (min-width: 2560px) {
          min-width: calc(450px * #{$zoom});
          min-height: calc(161px * #{$zoom});
        }
      }
    }

    &:nth-of-type(4)  {
      .benefits-section__image-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }

    &:nth-of-type(2) {
      align-items: center;
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;

      @media screen and (max-width: 640px) {
        flex-direction: column;
      }
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 26px;
    max-width: 50%;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }

    @media screen and (max-width: 640px) {
      max-width: 100%;
    }
  }

  &__item-number {
    color: #d1d1d1;
    font-size: 20px;
    font-family: $secondary-font;

    @media screen and (min-width: 2560px) {
      font-size: calc(20px * #{$zoom});
    }
  }

  &__item-title {
    color: $white;
    font-size: 32px;
    font-family: $secondary-font;
    text-transform: uppercase;

    @media screen and (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
    }
  }

  &__item-description {
    color: $white;
    font-size: 16px;
    font-family: $basic-font;
    line-height: 24px;

    @media screen and (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }
  }

  &__image-wrapper {
    position: relative;
    max-width: 50%;
    margin: 54px;

    @media screen and (max-width: 1024px) {
      margin: 20px;
    }

    @media screen and (max-width: 640px) {
      max-width: 80%;
      padding: 0;
    }
  }

  &__image-wrapper_3-animated {
    @media screen and (max-width: 1380px) {
      max-width: 25vw;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -30%;
      width: 100%;
      height: 1px;
      background-color: #d1d1d1;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    max-width: 375px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      width: 100% !important;
      max-width: 50%;
      object-fit: contain;
    }

    @media screen and (max-width: 640px) {
      max-width: 100%;
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(375px * #{$zoom});
    }
  }

  &__image_wrapper {
    position: absolute;
    z-index: 6;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  &__circle {
    position: absolute;
    background: #d30000;
    border-radius: 50%;
  }

  &__first-animation {
    top: 50%;
    left: 35%;
    z-index: 5;
    width: 60px;
    height: 60px;

    @media screen and (min-width: 2560px) {
      width: calc(60px * #{$zoom});
      height: calc(60px * #{$zoom});
    }
  }

  &__second-animation {
    bottom: 25%;
    width: 30px;
    height: 30px;

    @media screen and (min-width: 2560px) {
      width: calc(30px * #{$zoom});
      height: calc(30px * #{$zoom});
    }
  }

  &__third-animation {
    z-index: 5;
    width: 67px;
    height: 67px;

    @media screen and (min-width: 2560px) {
      width: calc(67px * #{$zoom});
      height: calc(67px * #{$zoom});
    }
  }

  &__fourth-animation {
    @media screen and (max-width: 1024px) {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      width: 32px;
      aspect-ratio: 1;
      background: #d30000;
      border-radius: 50%;

      @media screen and (min-width: 2560px) {
        width: calc(32px * #{$zoom});
      }
    }
  }

  &__image-wrapper_1-animated,
  &__image-wrapper_2-animated,
  &__image-wrapper_3-animated,
  &__image-wrapper_4-animated {
    @media screen and (max-width: 1024px) {
      display: none !important;
    }
  }
}
