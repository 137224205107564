@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.meet-contact {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 100px 40px;
    background: linear-gradient(180deg, #676767 0%, #000 37.16%);

    @media screen and (max-width: 480px) {
      padding: 60px 20px;
    }
  }

  &__content {
    width: 100%;
    max-width: 1240px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1240px * #{$zoom});
    }
  }

  &__name {
    @include section-name(#f1f1f4);
    @include line-above-text(238px, #f1f1f4);
    margin-bottom: 56px;

    @media screen and (max-width: 480px) {
      margin-bottom: 24px;
    }
  }

  &__title {
    @include section-heading(64px);
    margin-bottom: 65px;
    color: $white;
    font-family: $secondary-font--extrabold;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      margin-bottom: 24px;
      font-size: 26px;
      line-height: 32px;
    }

    @media screen and (min-width: 2560px) {
      margin-bottom: calc(65px * #{$zoom});
    }
  }

  &__details-wrapper {
    display: flex;
    flex-direction: row;
    gap: 56px;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }

    @media screen and (max-width: 480px) {
      gap: 24px;
    }

    @media screen and (min-width: 2560px) {
      gap: calc(56px * #{$zoom});
    }
  }

  &__image-wrapper {
    width: 100%;
    max-width: 368px;

    @media screen and (max-width: 480px) {
      max-width: 100%;
    }

    @media screen and (min-width: 2560px) {
      max-width: calc(368px * #{$zoom});
    }
  }

  &__image {
    width: 100%;

  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 65px;

    @media screen and (max-width: 480px) {
      gap: 32px;
    }

    @media screen and (min-width: 2560px) {
      gap: calc(65px * #{$zoom});
    }
  }

  &__details-text {
    color: $white;

    @media screen and (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }

    span {
      font-weight: 900;
      font-size: 14px;
      font-family: $secondary-font;
      text-transform: uppercase;

      @media screen and (min-width: 2560px) {
        font-size: calc(14px * #{$zoom});
      }

      &:first-of-type {
        font-size: 24px;
        text-transform: none;

        @media screen and (min-width: 2560px) {
          font-size: calc(24px * #{$zoom});
        }
      }
    }
  }

  &__details-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 376px;
    min-height: 48px;
    color: $black;
    font-weight: 800;
    font-size: 16px;
    font-family: $secondary-font;
    text-transform: uppercase;
    background-color: $white;
    border: 2px solid $white;
    cursor: pointer;
    transition: all 0.2s linear;

    @media screen and (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      min-height: calc(48px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }

    &:hover {
      color: $white;
      background-color: $black;
      transition: all 0.2s linear;
    }
  }
}
